<template>
    <div>
        <div class="top">
            <div class="topleft">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item :to="{ path: '/home' }">{{$t('home')}}</el-breadcrumb-item>
                    <el-breadcrumb-item>资金审核列表</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <div class="cont">
            <div class="listbox">
                <div class="listright" v-loading="loading">
                    <div class="search">
                        <div class="searchleft">
                            <el-form :inline="true" :model="searchform" class="demo-form-inline" @submit.native.prevent >
                                <el-form-item label="商户ID">
                                    <el-input v-model="searchform.mchId" style="width: 150px;" @keyup.enter.native="onSubmit" size="small"></el-input>
                                </el-form-item>
                                <el-form-item label="申请时间">
                                    <el-date-picker
                                    v-model="value1"
                                    type="datetimerange"
                                    size="small"
                                    range-separator="至"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    start-placeholder="开始时间"
                                    end-placeholder="结束时间">
                                    </el-date-picker>
                                </el-form-item>
                                <el-form-item label="状态">
                                    <el-select v-model="searchform.status" placeholder="请选择" style="width: 110px;">
                                        <el-option label="不限" value="">不限</el-option>
                                        <el-option label="审核中" value="1">审核中</el-option>
                                        <el-option label="成功" value="2">成功</el-option>
                                        <el-option label="失败" value="3">失败</el-option>
                                        <el-option label="拒绝" value="4">拒绝</el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item v-if="user.user.roles[0].id == 1" style="margin-top: 60;" label="国家" >
                                    <el-select v-model="searchform.nation" placeholder="请选择国家">
                                        <el-option :label="$t('buxian')" :value="null"></el-option>
                                        <el-option v-for="item in nationlist" :key="item.values" :label="item.name" :value="item.values"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item>
                                    <el-button size="small" type="primary" @click="onSubmit">{{$t('query')}}</el-button>
                                </el-form-item>
                            </el-form>
                        </div>
                    </div>
                    <el-table :data="list"
                        :header-cell-style="{'background':'#f5f5f5','font-weight':'bold','color':'#555555','padding':'6px 0'}" style="width: 100%">
                        <el-table-column prop="createTime" label="申请时间" :width="160">
                            <template slot-scope="scope">
                                {{scope.row.createTime|changedate}}
                            </template>
                        </el-table-column>
                        <el-table-column prop="orgId" label="商户ID"></el-table-column>
                        <el-table-column prop="orderNo" label="交易单号"></el-table-column>
                        <el-table-column prop="status" label="状态">
                            <template slot-scope="scope">
                                <el-tag v-if="scope.row.status==1" type="primary" size="mini">{{$t('shz')}}</el-tag>
                                <el-tag v-if="scope.row.status==2" type="success" size="mini">成功</el-tag>
                                <el-tag v-if="scope.row.status==3" type="warning" size="mini">{{$t('fksb')}}</el-tag>
                                <el-tag v-if="scope.row.status==4" type="warning" size="mini">拒绝</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column prop="moeny" label="申请金额"></el-table-column>
                        <el-table-column prop="cost" label="服务费"></el-table-column>
                        <el-table-column prop="comentarios" label="备注"></el-table-column>
                        <el-table-column prop="type" label="财务类型">
                            <template slot-scope="scope">
                                <el-tag v-if="scope.row.type==1">线上提现</el-tag>
                                <el-tag v-if="scope.row.type==2">线下提现</el-tag>
                                <el-tag v-if="scope.row.type==3">线上充值</el-tag>
                                <el-tag v-if="scope.row.type==4">线下充值 </el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column v-if="user.user.roles[0].id == 1" prop="nation" label="国家" width="88"></el-table-column>
                        <el-table-column prop="jobName" :label="$t('operator')" width="100">
                            <template slot-scope="scope">
                                <el-popconfirm title="确定收款吗？" v-if="scope.row.status == 1 && user.user.roles[0].id == 1"
                                    @confirm="cobrador(scope.row)" content="审核通过">
                                    <el-button size="small" type="warning" icon="el-icon-check" circle slot="reference" plain></el-button>
                                </el-popconfirm>

                                <el-popconfirm title="确定撤销吗？" v-if="scope.row.status == 1 && user.user.roles[0].id == 2"
                                    @confirm="cancel(scope.row)" content="撤销成功">
                                    <el-button size="small" type="warning" icon="el-icon-check" circle slot="reference" plain></el-button>
                                </el-popconfirm>

                                <!-- <el-popconfirm title="确定提现吗？" v-if="scope.row.iden == 1 && scope.row.status == 1"
                                    @confirm="auth(scope.row)" content="审核通过">
                                    <el-button size="small" type="warning" icon="el-icon-check" circle slot="reference" plain></el-button>
                                </el-popconfirm> -->
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="page">
                        <app-page :total="total" :current="pageNo" :pageSize="pageSize"
                            @handleCurrentChange="currentchange" @handleSizeChange="handchange"></app-page>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import apiurl from "@/api/public"
    import _api from "@/api/index"
    import Treeselect from '@riophae/vue-treeselect'
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    import {
        LOAD_CHILDREN_OPTIONS
    } from '@riophae/vue-treeselect'
    export default {
        components: {
            Treeselect
        },
        data() {
            var rolesValid = (rule, value, callback) => {
                callback()
            }
            return {
                loading: false,
                showadd: false,
                list: [],
                searchform:{},
                total: 1,
                pageNo: 1,
                pageSize: 10,
                value1: null,
                nation: null,
                nationlist:[],
            }
        },
        created() {
            this.user = JSON.parse(localStorage.getItem("userinfo"))
            this.getList()
            this.getnation()
        },
        methods: {
            getnation() {
                _api.get(apiurl.nationlist, {}).then(res => {
                    let newarr = []
                    for(let item of res.data){
                        newarr.push(item)
                    }
                    this.nationlist = newarr
                })
            },
            auth(row) {
                _api.get(apiurl.auditoria, {
                    audId: row.id,
                }).then(res => {
                    this.$notify({
                        title: this.$t('success'),
                        message: this.$t('success'),
                        type: 'success'
                    });
                    this.getList()

                }).catch(err => {
                    this.$notify.error({
                        title: this.$t('error'),
                        message: err.data.message
                    });
                })
            },
            cobrador(row) {
                _api.get(apiurl.cobrador, {
                    audId: row.id,
                    status: 2,
                }).then(res => {
                    this.$notify({
                        title: this.$t('success'),
                        message: this.$t('success'),
                        type: 'success'
                    });
                    this.getList()

                }).catch(err => {
                    this.$notify.error({
                        title: this.$t('error'),
                        message: err.data.message
                    });
                })
            },
            cancel(row) {
                _api.get(apiurl.cobrador, {
                    audId: row.id,
                    status: 3,
                }).then(res => {
                    this.$notify({
                        title: this.$t('success'),
                        message: this.$t('success'),
                        type: 'success'
                    });
                    this.getList()

                }).catch(err => {
                    this.$notify.error({
                        title: this.$t('error'),
                        message: err.data.message
                    });
                })
            },
            onSubmit() {
                this.pageNo = 1
                this.getList()
            },
            getList() {
                this.loading = true
                _api.get(apiurl.mencionar, {
                    page: this.pageNo,
                    size: this.pageSize,
                    mchId: this.searchform.mchId,
                    createTime: this.value1,
                    nation: this.searchform.nation,
                    status: this.searchform.status,
                }).then(res => {
                    this.loading = false
                    this.list = res.data.list
                    this.total = res.data.total
                })
            },
            handchange(data) { //分页Size变化
                this.pageSize = data
                this.pageNo = 1
                this.getList()
            },
            currentchange(data) { //当前页变化
                this.pageNo = data
                this.getList()
            },
        }
    }
</script>
<style scoped>
    .lefttop {
        padding: 8px;
    }

    .listbox {
        display: flex;
    }

    .listleft {
        width: 20%;
        border: 1px solid #EBEEF5;
    }

    .listright {
        width: 100%;
        margin-left: 1%;
    }

    .listrighttop {
        padding: 0 10px;
        background: rgb(245, 245, 245);
        height: 40px;
        overflow: hidden;
        line-height: 40px;
        border-bottom: 1px solid #EBEEF5;
    }

    .listrighttop strong {
        float: left;
    }

    .listrighttop span {
        float: right;
    }

    ::v-deep .vue-treeselect__control,
    ::v-deep .vue-treeselect__placeholder,
    ::v-deep .vue-treeselect__single-value {
        height: 30px;
        line-height: 30px;
    }
</style>